import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import { AvForm, AvField } from "availity-reactstrap-validation"
//import Tableneedy from "./Tableneedy"
import img2 from "./img-22.jpg"
import { ins } from "./ins"
import Cookies from "js-cookie"
import dateFormat from "dateformat"
import {
  Table,
  Button,
  Container,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Input,
  Label,
  CardSubtitle,
  Row,
  Form,
  ModalBody,
} from "reactstrap"
export default class orphanageinformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        adress: "",
        attendant_count: "",
        child_count: "",
        city: "",
        country: "",
        electric_status: "",
        email: "",
        kitchen_status: "",
        lyceum_status: "",
        name: "",
        number_of_bed: "",
        number_of_rooms: "",
        opening_date: "",
        phone: "",
        water_status: "",
        wc_bath_lavabo_status: "",
      },
    }
  }
  componentDidMount() {
    this.request()
  }
  request = () => {
    var config = {
      method: "get",
      url:
        "getOrphanageDetail?societyId=" +
        Cookies.get("societyId") +
        "&orphanageId=" +
        this.props.match.params.id,
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    ins(config)
      .then(res => {
        this.setState({ data: res.data })
      })
      .catch(error => {})
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Yetim Takip </title>
          </MetaTags>
          <div></div>
          <Container fluid>
            <Row>
              <Card>
                <CardTitle>
                  <Row className="mb-2 mt-2">
                    <Col sm="4">
                      <div className="search-box  mb-2 d-inline-block">
                        <div className="position-relative">
                          YETİMHANE BİLGİLERİ
                        </div>
                      </div>
                    </Col>
                    <Col sm="8"></Col>
                  </Row>
                </CardTitle>
                <CardBody className="p-0">
                  <Table responsive striped className="table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th style={{ width: "50%" }}> </th>
                        <td>
                          <CardImg
                            top
                            className="img-fluid w-25"
                            src={img2}
                            alt="Skote"
                          />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Yetimhane Adı </th>
                        <td>{this.state.data.name}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanenin Açılma Tarihi </th>
                        <td>
                          {dateFormat(this.state.data.opening_date, "dd")}.
                          {dateFormat(this.state.data.opening_date, "mm")}.
                          {dateFormat(this.state.data.opening_date, "yyyy")}
                        </td>
                      </tr>
                      <tr>
                        <th>Yetimhane Adresi</th>
                        <td>{this.state.data.adress}</td>
                      </tr>
                      <tr>
                        <th>Yetimhane Telefonu</th>
                        <td>{this.state.data.phone}</td>
                      </tr>
                      <tr>
                        <th>Yetimhane Mail Adresi</th>
                        <td>{this.state.data.email}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanenin Bulunduğu Ülke</th>
                        <td>{this.state.data.country}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanenin Bulunduğu Şehir</th>
                        <td>{this.state.data.city}</td>
                      </tr>

                      <tr>
                        <th>Toplam Çalışan Sayısı</th>
                        <td>{this.state.data.attendant_count}</td>
                      </tr>
                      <tr>
                        <th>Toplam Çocuk Sayısı</th>
                        <td>{this.state.data.child_count}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanede Elektrik Var Mı</th>
                        <td>{this.state.data.electric_status}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanede Su Var Mı</th>
                        <td>{this.state.data.water_status}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanedeki Toplam Oda Sayısı</th>
                        <td>{this.state.data.number_of_rooms}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanedeki Toplam Yatak Sayısı</th>
                        <td>{this.state.data.number_of_bed}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanedeki Eğitim Salonu Var Mı</th>
                        <td>{this.state.data.lyceum_status}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanede Mutfak Var Mı</th>
                        <td>{this.state.data.kitchen_status}</td>
                      </tr>
                      <tr>
                        <th>Yetimhanede Banyo Var Mı</th>
                        <td>{this.state.data.wc_bath_lavabo_status}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
