import React from "react"
import { Redirect } from "react-router-dom"

//page
// import İnformation from "../pages/page/information"
import Needy from "../pages/page/needy"
import Orphanage from "../pages/page/orphanage"
import Needinformation from "../pages/page/needinformation"
import Orphanageinformation from "../pages/page/orphanageinformation"
import Pages404 from "../pages/page/pages404"
//import Aa from "../pages/page/aa"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Donates from "pages/page/YapilanBagislar/Donates"

const userRoutes = [
  //page
  // { path: "/information", component: İnformation },
  { path: "/Needy", component: Needy },
  { path: "/Orphanage", component: Orphanage },
  // { path: "/Society", component: Society },
  { path: "/Needinformation/:id", component: Needinformation },
  //{ path: "/Aa", component: Aa },
  { path: "/Orphanageinformation/:id", component: Orphanageinformation },
  { path: "/pages404", component: Pages404 },
  { path: "/Donates", component: Donates },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/Needy" /> },
  { path: "", exact: false, component: () => <Redirect to="/Needy" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { userRoutes, authRoutes }
