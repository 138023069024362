import React, { useEffect, useState } from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  Link,
  withRouter,
} from "react-router-dom"
import dateFormat from "dateformat"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
//import swal from "sweetalert"
//import Cookies from "js-cookie"
import {
  Button,
  Container,
  Card,
  CardBody,
  Col,
  CardTitle,
  CardSubtitle,
  CardImg,
  Modal,
  ModalHeader,
  ModalBody,
  CardText,
  Form,
  Input,
  Label,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap"

function photogallery(props) {
  const [photoIndex, setphotoIndex] = useState(0)
  const [isFits, setisFits] = useState(false)
  return (
    <React.Fragment>
      <Button
        color="secondary"
        className="btn btn-secondary "
        onClick={() => {
          setisFits(true)
        }}
      >
        Karne Görüntüle
      </Button>
      {isFits ? (
        <Lightbox
          mainSrc={
            "https://dernekapi.yetimsevindir.com/images/" + props.photo
          }
          enableZoom={true}
          onCloseRequest={() => {
            setisFits(!isFits)
          }}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default photogallery
