import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//import Tableneedy from "./Tableneedy"
import img2 from "./img-22.jpg"
import { ins } from "./ins"
import Slidewithcontrol from "./slidewithcontrol"
import Cookies from "js-cookie"
import Slidewithindicator from "./slidewithindicator"
import {
  Button,
  Container,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardTitle,
  Col,
  Input,
  Label,
  CardSubtitle,
  Spinner,
  Row,
  Form,
  ModalBody,
} from "reactstrap"
export default class orphanage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [
        {
          id: "",
          adress: "",
          attendant_count: "",
          child_count: "",
          name: "",
          phone: "",
          photo: [{}],
        },
      ],
      datavisible: false,
    }
  }

  componentDidMount() {
    this.request()
  }
  request = () => {
    var config = {
      method: "get",
      url: "Orphanage/Orphanages",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    ins(config)
      .then(res => {
        this.setState({ data: res.data, datavisible: true })
        var pth = []
        res.data.map(item => {
          if (item.photo.length == 0) {
            pth.push({ src: "img1", altText: 0, caption: 0 })
          } else {
            item.photo.map((k, l) => {
              pth.push({ src: k, altText: l, caption: l })
            })
          }
        })
        this.setState({ propspht: pth })
      })
      .catch(error => {})
  }
  roadorphaninfo = e => {
    window.location.href = "/orphanageinformation/" + e.target.id
    //e.target.id
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Yetim Takip </title>
          </MetaTags>
          <div></div>
          <Container fluid>
            {this.state.datavisible ? (
              <Row className="g-3">
                {this.state.data.length > 0
                  ? this.state.data.map((i, index) => {
                      return (
                        <Col mg={4} xl={4} sm={6} key={index}>
                          <Card className="h-100">
                            <div style={{ height: "260px" }}>
                              <Slidewithindicator data={i.photo} />
                            </div>
                            <CardBody>
                              <CardTitle className="mt-0">{i.name}</CardTitle>
                              <CardText>{i.country}</CardText>
                            </CardBody>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item">{i.state}</li>
                              <li className="list-group-item">
                                Yetimhanede toplam {i.child_count} çocuk
                                kalmaktadır.
                              </li>
                            </ul>
                          </Card>
                        </Col>
                      )
                    })
                  : "Yetimhane bilgisi bulunamadı."}
              </Row>
            ) : (
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center mb-2"
              >
                <Spinner className="ms-4  " color="secondary" />
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
