import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//import Tableneedy from "./Tableneedy"
//import { ins } from "./ins"
import Slidewithcontrol from "./slidewithcontrol"
import Cookies from "js-cookie"
import {
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  CardImg,
  Input,
  Label,
  Spinner,
  CardSubtitle,
  Row,
  Form,
  ModalBody,
} from "reactstrap"
import img2 from "./img-2.jpg"
import { ins } from "./ins"
export default class needy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      needdata: [
        {
          age: 0,
          country: "",
          id: 0,
          name: "",
          orphanage_name: "",
          photo: "",
        },
      ],
      needvisible: false,
    }
  }
  componentDidMount() {
    this.request()
  }

  request = e => {
    var config = {
      method: "get",
      url: "Orphan/Orphans",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    ins(config)
      .then(async res => {
        this.setState({ needvisible: true, needdata: res.data })
      })
      .catch(error => {
        // if (error.response.status == 400) {
        //   swal("Kayıt Bulunamadı", {
        //     title: "Size atanmış bir yetim yok.",
        //     icon: "error",
        //     buttons: true,
        //     timer: 7000,
        //   })
        // } else {
        //   swal("Bir Sorun Oluştu", {
        //     title: "bir şeyler ters gitti.",
        //     icon: "error",
        //     buttons: false,
        //     timer: 3000,
        //   })
        // }
      })
  }

  road = e => {
    window.location.href = "/needinformation/" + e.target.id
    //e.target.id
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Yetim Takip </title>
          </MetaTags>
          <div></div>
          <Container fluid>
            {this.state.needvisible ? (
              <Row>
                {this.state.needdata.length > 0
                  ? this.state.needdata.map((i, index) => {
                      return (
                        <Col mg={4} xl={4} key={index}>
                          <Card>
                            <div style={{ height: "260px", display: "flex" }}>
                              <CardImg
                                top
                                style={{ height: "auto", maxHeight: "260px" }}
                                className="img-fluid m-auto w-auto "
                                src={
                                  i.photo
                                    ? "https://dernekapi.yetimsevindir.com/images/" +
                                      i.photo
                                    : img2
                                }
                                alt=""
                              />
                            </div>
                            <CardBody>
                              <CardTitle className="mt-2 px-1">
                                {i.name}({i.age})
                              </CardTitle>
                              <hr />
                              <ul className="list-group list-group-flush px-1">
                                <li className=" d-flex">
                                  <strong style={{ width: "50%" }}>
                                    Yaşadığı Yer{" "}
                                  </strong>
                                  <p> {i.country}</p>
                                </li>
                                <li className=" d-flex">
                                  <strong style={{ width: "50%" }}>
                                    {" "}
                                    Yetimhanesi{" "}
                                  </strong>
                                  <p>
                                    {i.orphanage_name
                                      ? i.orphanage_name
                                      : "Yetimhane Kaydı Yok"}
                                  </p>
                                </li>
                              </ul>
                              <CardBody className=" row">
                                <Button
                                  id={i.id}
                                  color="success"
                                  outline
                                  className=""
                                  onClick={this.road}
                                >
                                  Bilgilerini Görüntüle
                                </Button>
                              </CardBody>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })
                  : "Yetim bulunamadı..."}
              </Row>
            ) : (
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center mb-2"
              >
                <Spinner className="ms-4  " color="secondary" />
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
