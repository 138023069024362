import React, { Component } from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap"

// Carousel images

import img3 from "./img-22.jpg"
import img4 from "./img-4.jpg"
import img5 from "./img-5.jpg"

const items = [
  {
    src: img3,
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    src: img4,
    altText: "Slide 2",
    caption: "Slide 2",
  },
  {
    src: img5,
    altText: "Slide 3",
    caption: "Slide 3",
  },
]

class Slidewithindicator extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0, s_items: [{}] }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }
  componentDidMount() {
    var pth = []
    if (this.props.data.length == 0) {
      pth.push({ src: img3, altText: 0, caption: 0 })
    } else {
      this.props.data.map((item, t) => {
        pth.push({
          src: "https://dernekapi.yetimsevindir.com/images/" + item,
          altText: t,
          caption: t,
        })
      })
    }
    this.setState({ s_items: pth })
  }

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === this.state.s_items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.data.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    const slides = this.state.s_items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img
            style={{
              width: "100%",
              objectFit: "contain",
              height: "auto",
              maxHeight: "260px",
            }}
            src={item.src}
            alt={item.altText}
          />
        </CarouselItem>
      )
    })

    return (
      <React.Fragment>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={this.state.s_items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
            className="dark-carousel-control-prev"
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
            className="dark-carousel-control-next"
          />
        </Carousel>
      </React.Fragment>
    )
  }
}

export default Slidewithindicator
