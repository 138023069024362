import moment from "moment"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

// import MetaTags from "react-meta-tags"
// import axios from "axios"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Button, UncontrolledTooltip } from "reactstrap"
import "./datatables.scss"

export const TableItem = props => {
  const data = props.data

  return (
    <React.Fragment>
      <Tr style={{ cursor: "default" }}>
        <Td>
          <b>{data.price} TL</b>
        </Td>
        <Td>{moment(data.date).format("DD.MM.YYYY")}</Td>
        <Td>
          {data.approval ? (
            <i
              className="mdi mdi-check-bold
 text-success
"
            />
          ) : (
            <i
              className="mdi mdi-close-thick text-danger
"
            />
          )}
        </Td>
      </Tr>
    </React.Fragment>
  )
}
