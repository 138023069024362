import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import { Card, CardBody, Container, Row } from "reactstrap"
import { ins } from "../ins"
import { TableItem } from "./TableItem"

const Donates = props => {
  const [data, setData] = useState([])
  useEffect(() => {
    var config = {
      method: "get",
      url: "Donation/DonationList",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }

    ins(config)
      .then(function (response) {
        setData(response.data)
      })
      .catch(function (error) {})
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Yetimhane Bilgi Sistemi</title>
        </MetaTags>
        <Container fluid>
          <div className="Card">
            <div className=" bg-white border-bottom card-header">
              <Row className="d-flex justify-content-between align-items-center  ">
                <div className=" col-lg-3 d-flex align-items-center h4 justify-content-center justify-content-lg-start  ">
                  <p> Yetim Listesi</p>
                </div>
              </Row>
            </div>

            <Card className="mt-1 mb-1"></Card>
            <Card className="overflow-hidden">
              <CardBody className="overflow-hidden p-0">
                {data.length == 0 ? (
                  <div className="alert alert-warning">"Kayıt Yok"</div>
                ) : (
                  <div style={{ overflowX: "auto", minHeight: "50vh" }}>
                    <Table className="table table-hover table table-striped">
                      <Thead
                        style={{
                          background: "#17202a",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="thead-dark"
                      >
                        <Tr>
                          <Th>Bağış Miktarı</Th>
                          <Th>Tarih</Th>

                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data.map((item, key) => {
                          return <TableItem key={key} data={item} />
                        })}
                      </Tbody>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Donates
