import axios from "axios"

export const ins = axios.create({
  baseURL: "https://api.yetimsevindir.com/api/",
  //timeout: 10000,
  ContentType: "application/json",
})

ins.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem("token")
      window.location.href = "/Login"
    }
    throw error
  }
)
